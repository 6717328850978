import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'index',
        component: () =>
            import ('../views/index.vue'),
        children: [{
                path: '/',
                name: 'home',
                component: () =>
                    import ('../views/Home.vue'),
                meta: {
                    index: 0
                }
            },
            {
                path: '/about',
                name: 'about',
                component: () =>
                    import ('../views/About.vue'),
                meta: {
                    index: 1
                }
            },
            {
                path: '/list',
                name: 'list',
                component: () =>
                    import ('../views/list.vue'),
                meta: {
                    index: 1
                }
            },
            {
                path: '/detail',
                name: 'detail',
                component: () =>
                    import ('../views/detail.vue'),
                meta: {
                    index: 0
                }
            },
            {
                path: '/listPage',
                name: 'listPage',
                component: () =>
                    import ('../views/listPage.vue'),
                meta: {
                    index: 1
                }
            },
            {
                path: '/company',
                name: 'company',
                component: () =>
                    import ('../views/company.vue'),
                meta: {
                    index: 1
                }
            },
            {
                path: '/news',
                name: 'news',
                component: () =>
                    import ('../views/news.vue'),
                meta: {
                    index: 2
                }
            },
            {
                path: '/business',
                name: 'business',
                component: () =>
                    import ('../views/business.vue'),
                meta: {
                    index: 3
                }
            },
            {
                path: '/party',
                name: 'party',
                component: () =>
                    import ('../views/party.vue'),
                meta: {
                    index: 4
                }
            },
            {
                path: '/hr',
                name: 'hr',
                component: () =>
                    import ('../views/hr.vue'),
                meta: {
                    index: 5
                }
            },
            {
                path: '/search',
                name: 'search',
                component: () =>
                    import ('../views/search.vue'),
                meta: {
                    index: 6
                }
            },
            {
                path: '/report',
                name: 'hr',
                component: () =>
                    import ('../views/report.vue'),
                meta: {
                    index: 6
                }
            },
            {
                path: '/petition',
                name: 'hr',
                component: () =>
                    import ('../views/petition.vue'),
                meta: {
                    index: 7
                }
            },
            {
                path: '/contact',
                name: 'contact',
                component: () =>
                    import ('../views/contact.vue'),
                meta: {
                    index: 8
                }
            },
            {
                path: '/specail',
                name: 'specail',
                component: () =>
                    import ('../views/specail.vue'),
                meta: {
                    index: 9
                }
            },
        ]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router